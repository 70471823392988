/*
 | ------------------------------------------------------------------------------------
 |                      Not Found Catch All
 | ------------------------------------------------------------------------------------
 */
import { type RouteRecordRaw } from 'vue-router';

export const notFoundRoutes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () => import('@/views/other/NotFound.vue')
  },
];
