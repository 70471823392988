<script lang="ts">
import { defineComponent } from "vue";
import { useAuthStore, type AuthStore } from "@/store/authentication.ts";
import { usePreferencesStore } from "@/store/preferences.ts";
import { useNotificationsStore, type NotificationsStore, type Message } from "@/store/notification.ts";

import { storeToRefs } from 'pinia'

export default defineComponent({
//  setup() {
//    const authStore = useAuthStore();
//    const authentication = storeToRefs(authStore);
//    return { authentication };
//  },
  computed: {
    global_messages(): Message[] { const notiStore = useNotificationsStore(); return notiStore.messages; },
    authentication(): AuthStore { const authstore = useAuthStore(); return authstore; },
    preferences(): ReturnType<typeof usePreferencesStore> { const prefstore = usePreferencesStore(); return prefstore; },
    isDark(): boolean { return this.preferences.isDark; },
    isDrawerOpen(): boolean { return this.preferences.isDrawerOpen; },
  },
  methods: {
    clearMessages(): void {
      const notiStore:NotificationsStore = useNotificationsStore();
      notiStore.clearMessages();
    },
    notifySuccess(msg ?: string): void {
      const notiStore = useNotificationsStore();
      notiStore.saveSuccessful(msg/* ? msg : 'Success'*/);
    },
    notifyError(msg: string): void {
      const notiStore = useNotificationsStore();
      notiStore.addError(msg);
    },
    toggleDrawer(): void {
      this.preferences.toggleDrawer(!this.preferences.isDrawerOpen);
    },
    toggleDarkMode(): void {
      this.preferences.toggleDarkMode(!this.preferences.isDark);
    },
    toggleBrowserTimeZone(): void {
      this.preferences.updateTimezone(!this.preferences.timezone.useBrowserTime);
    },
  },
});
</script>
