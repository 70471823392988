<script lang="ts">
import { defineComponent } from "vue";
import { useAuthStore, type AuthStore } from "@/store/authentication.ts";
import type {Router, RouteLocationNormalized } from "vue-router";
import type {ConfirmationServiceMethods} from 'primevue/confirmationservice';

export default defineComponent({
  computed: {
    localTZ(): string { return new Date().toLocaleTimeString(undefined,{ timeZoneName:'short' }).split(' ')[2]; },
    locale(): string|null { return this.authentication.preferred_locale; },
  },
  methods: {
    asLocalDate(t ?: number): string {
      //      if (t > 3600) dayjs.unix(t).format('YYYY-MM-DD HH:mm:ss');
      if (t && t > 3600) return new Date(t*1000).toLocaleDateString(this.locale);
      return '';
    },
    asLocalDateTime(t ?: number): string {
      //      if (t > 3600) dayjs.unix(t).format('YYYY-MM-DD HH:mm:ss');
      if (t && t > 3600) return new Date(t*1000).toLocaleString(this.locale);
      return '';
    },
    asLocalDateTimeWithZone(t ?: number): string {
      if (t && t > 3600) return new Date(t*1000).toLocaleString(this.locale) + ' (' + this.localTZ +')';
      return '';
    },
    asLocalDateTimeDense(t ?: number):string {
      if (!t) return '';
      const td = new Date();
      if (t <= 3600) return '';
      const d = new Date(t*1000);
      if (d.getDay() != td.getDay()) {
        return d.toLocaleString(this.locale);// + ' (' + this.localTZ +')';      } else {
      } else {
        return d.toLocaleTimeString(undefined, { timeZoneName:'short' }).split(' ')[1];
      }
    },
    // Object compare method
    compareObjects(obj1:any, obj2:any): boolean {
      if (!obj1) return false;
      if (!obj2) return false;
      
      //Loop through properties in object 1
      for (let p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
        switch (typeof (obj1[p])) {
          //Deep compare objects
        case 'object':
          if (!this.compareObjects(obj1[p], obj2[p])) return false;
          break;
          //Compare function code
        case 'function':
          if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
          break;
          //Compare values
        default:
          if (obj1[p] != obj2[p]) return false;
        }
      }
      //Check object 2 for any extra properties
      for (let p in obj2) {
        if (typeof (obj1) == 'undefined' || typeof (obj1[p]) == 'undefined') return false;
      }
      return true;
    },
  },
});
</script>
