import superCheck from "../guards/superCheck.js";
import { type RouteRecordRaw } from 'vue-router';

export const testingRoutes: RouteRecordRaw[] = [
  {
    path: '/testing',
    name: 'Testing Page',
    component: () => import('@/views/testing/Testing.vue'),
    beforeEnter: superCheck
  },
  {
    path: '/automated-reports',
    name: 'Automated Reports',
    component: () => import("../../views/reporting/Automated.vue"),
    beforeEnter: superCheck
  },
];
