import { defineStore } from 'pinia';

// Define the types for the notification and message objects
interface Notification {
  id: number;
  icon: string | null;
  title: string;
  message: string;
}

export interface Message {
  severity: 'success' | 'error' | 'info' | 'warn';
  summary: string;
  life: number;
}

interface NotificationsState {
  messages: Message[];
  forceShow: boolean;
  notifications: Notification[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface NotificationsGetters {
}

interface NotificationsActions {
    addMessage(message: { severity: 'success' | 'error' | 'info' | 'warn'; msg: string }): void;
}

export const useNotificationsStore = defineStore/*<'notifications', NotificationsState, NotificationsGetters, NotificationsActions>*/('notifications', {
  state: (): NotificationsState => ({
    messages: [],
    forceShow: false,
    notifications: [],
    /*
      {
        id: 2,
        icon: null,
        title: 'Test Notification',
        message: 'This is a testing message that should be large enough to show something'
      }*/
  }),
  actions: {
    clearNotification(id: number): void {
      // TODO handle clearing the notification from the server side
      this.notifications = this.notifications.filter(notification => notification.id !== id) as Notification[];
    },
    clearAllNotifications(): void {
      // TODO handle clearing the notifications from the server
      this.notifications = []
    },
    addMessage(message: { severity: 'success' | 'error' | 'info' | 'warn'; msg: string }): void {
      this.messages.push({ severity: message.severity, summary: message.msg, life:3000 });
      if (this.messages.length > 20) this.messages.shift();
    },
    clearMessages(): void {
      this.messages = [];
      this.forceShow = false;
    },
    saveSuccessful(v?: string): void {
      this.addMessage({ severity: 'success', msg: v ?? 'Saved Successfully' });
    },
    addError(v?: string | string[]): void {
      if (Array.isArray(v)) {
        v.forEach((m) => {
          this.addMessage({ severity:'error', msg:m });
        });
      } else {
        this.addMessage({ severity:'error', msg:v ?? 'An error has occured' });
      }
    },
  },
});

export type NotificationsStore = ReturnType<typeof useNotificationsStore>;
