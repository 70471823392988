import { useAuthStore } from "@/store/authentication.ts";
import { type RouteLocationNormalized, type NavigationGuardNext } from 'vue-router';

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authentication = useAuthStore();
  if (!authentication.access?.superAdmin && !authentication.access?.permissions?.monitoring?.view) {
    next('/');
  } else {
    next();
  }
}
