import { router } from '../router/index';
import _ from 'lodash';
import { defineStore } from 'pinia'
import type { AxiosResponse } from 'axios';
import axios, { AxiosError, isAxiosError } from 'axios';
import type { Router } from 'vue-router';

interface User {
  id: number;
  usergroup: number;
}

interface LoginResponse {
  accessToken: string | null;
}

interface UserResponse {
  data: User;
  permissions: Permissions;
  preferred_locale: string | null;
}

interface UserLogin {
  username: string;
  password: string;
}

interface PermissionDetails {
  create: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
  view?: boolean;
  test?: boolean;
}

interface Permissions {
  dirty: boolean;
  companies: PermissionDetails;
  patients: PermissionDetails;
  tags: PermissionDetails;
  monitoring: PermissionDetails;
  pack: PermissionDetails;
  events: PermissionDetails;
  contacts: PermissionDetails;
  notifications: PermissionDetails;
}

interface AccessState {
  superAdmin: boolean;
  superAdminView: boolean;
  superStatus: boolean;
}

interface Access {
  tokenType: string | null;
  accessToken: string | null;
  superAdmin: boolean;
  superAdminView: boolean;
  superStatus: boolean;
  user: User | null;
  permissionsCache: Permissions | null;
  permissions: Permissions | null;
}

interface Jobs {
  check: number | null;
  checking: boolean;
}

interface AuthState {
  isLoggedIn: boolean;
  access: Access;
  preferred_locale: string | null;
  jobs: Jobs;
}

// TODO - wait for issue 381 in pinia-plugin-persistedstate
/* xxeslint-disable @typescript-eslint/no-unsafe-call */

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: (): AuthState => ({
    isLoggedIn: false,
    access: {
      tokenType: null,
      accessToken: null,
      superAdmin: false,
      superAdminView: false,
      superStatus: false,
      user: null,
      permissionsCache: null,
      permissions: null,
    },
    preferred_locale: null,
    jobs: {
      check: null,
      checking: false
    },
  }),
  actions: {
    boot(): void {
      this.jobs.checking = false;
      this.jobs.check = null;
      this.validateAuth();
    },
    toggleSuperAdmin(status: boolean): void {
      if (this.access.permissions) {
        this.access.permissions.dirty = true;
        this.access.superAdmin = status;
      }
    },
    setAccessState(params: AccessState): void {
      const keys: (keyof AccessState)[] = ["superAdmin", "superAdminView", "superStatus"];
      keys.forEach(key => {
        if (key in params) {
          // @xts-expect-error: TODO
          // exslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.access[key] = params[key];
        }
      });
    },
    updateAccessPermissions(permissions: Permissions): void {
      this.access.permissionsCache = permissions;
      if (this.access.permissions === null || !this.access.permissions.dirty) {
        this.resetPermissions();
      }
    },
    updatePreferredLocale(loc: string | null): void {
      this.preferred_locale = loc;
    },
    updatePermission(change: { parent: string; permission: string; status: boolean }): void {
      if (this.access.permissions) {
        this.access.permissions.dirty = true;
        const parentObj = this.access.permissions[change.parent as keyof Permissions];
        if (parentObj && typeof parentObj === 'object') {
          (parentObj as Record<string,boolean>)[change.permission] = change.status;
        }
      }
    },
    resetPermissions(): void {
      this.access.superAdmin = this.access.superStatus;
      if (this.access.permissionsCache) {
        this.access.permissions = JSON.parse(JSON.stringify(this.access.permissionsCache)) as Permissions;
        if (this.access.permissions) {
          this.access.permissions.dirty = false;
        }
      }
    },
    async loadPermissions(): Promise<void> {
      try {
        const response = await axios.get<UserResponse>(`/api/smart/user`);
        this.access.user = response.data.data;
        this.isLoggedIn = true;
        this.jobs.checking = false;
        const group = this.access.user.usergroup;
        const as: AccessState = {
          superStatus: group === 1,
          superAdmin: group === 1,
          superAdminView: group === 1 || group === 6,
        };
        this.setAccessState(as);
        this.updateAccessPermissions(response.data.permissions);
        this.updatePreferredLocale(response.data.preferred_locale);
        this.finished();
      } catch(error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) { // if we got a unauthorised response then we logout otherwise there is no point
            void this.logout();
            setTimeout(() => { // TODO - why
              this.jobs.checking = false;
            }, 3000);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    },
    _checkAuthState(): void {
      if (!this.jobs.checking) {
        this.jobs.checking = true;
        void this.loadPermissions();
      }
    },
    checkAuthState(): void {
      const debouncedCheck = _.debounce(() => {
        this._checkAuthState();
      }, 500);
      debouncedCheck();
    },
    finished(): void {},
    
    validateAuth(): void {
      if (this.access?.tokenType && this.access?.accessToken) {
        this._validateAuth();
      }
    },

    _validateAuth(): void {
      if (axios) {
        axios.defaults.headers.common['Authorization'] = `${this.access.tokenType} ${this.access.accessToken}`;
        if (this.access.accessToken) {
          this.isLoggedIn = true;
          this.checkAuthState();
      
          if (this.jobs.check === null) {
            this.jobs.check = setInterval(() => {
              this.checkAuthState();
            }, 5 * 60 * 1000);
          }
        }
      }
    },
    
    togglePermissionsReset(): void { this.resetPermissions(); },
    
    async login(details: UserLogin): Promise<void> {
      const response = await axios.post<LoginResponse>(`/api/smart/login`, details);
      this.access.tokenType = 'Bearer';
      this.access.accessToken = response.data.accessToken;
      this.validateAuth();
      await router.replace('/');
      //          const companiesStore = useCompaniesStore();
      //          companiesStore.loadCompanies();
    },
    async logout(): Promise<void> {
      try {
        await axios.post(`/api/smart/logout`);
      } catch(error: unknown) {
        if (axios.isAxiosError(error)) { // Type guard to check if it's an AxiosError
          console.error('Logout error:', error.message);
        } else {
          console.error('Unexpected error:', error);
        }
      }
      //          window.localStorage.clear();
      window.sessionStorage.clear();
      this.resetAuthentication();
      //          const preferencesStore = usePreferencesStore();
      //          preferencesStore.resetPreferences();
      this.validateAuth();
      await router.replace('/login').catch(() => {
        // Ignore navigation errors if already on login page
      });
    },
    resetAuthentication(): void {
      this.isLoggedIn = false;
      if (this.access) {
        this.access.tokenType = null;
        this.access.accessToken = null;
        this.access.superAdmin = false;
        this.access.superAdminView = false;
        this.access.superStatus = false;
        this.access.user = null;
        this.access.permissionsCache = null;
        this.access.permissions = null;
      }
    },
  }
});

export type AuthStore = ReturnType<typeof useAuthStore>;
