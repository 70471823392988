import { defineStore } from 'pinia'
import type { Company, CompanyId } from "@/types/Company.ts";

interface PreferencesState {
      isDark: boolean;
      isDrawerOpen: boolean;
      transitions: boolean;
      monitoring: {
        search: string;
        companies: CompanyId[],
        importance: boolean;
        tableView: boolean;
        status:null,
      },
      tags: {
        search: string;
        tableView: boolean;
        status: null,
        companies: CompanyId[],
      },
      company: {
        search: string;
        tableView: boolean;
        status: null,
      },
      appusers: {
        search: string;
        tableView: boolean;
        status: null,
        companies: CompanyId[]|null,
      },
      companies: CompanyId[]|null,
      barcoding: {
        default: string|null,
        preference: string|null
      },
      timezone: {
        useBrowserTime: boolean;
      },
      patient: {
        tableView: boolean;
        search: string;
        status:null,
        companies: CompanyId[],
      },
      reporting: {
        tableView: boolean;
        search: string;
        status:null,
        companies: CompanyId[],
      },
      absoluteScheduleTableView: boolean;
}

export const usePreferencesStore = defineStore('preferences', {
  persist: true,
  state: (): PreferencesState => ({
    isDark: false,
    isDrawerOpen: false,
    transitions: true,
    monitoring: {
      search: '',
      companies: [],
      importance: false,
      tableView: false,
      status:null,
    },
    tags: {
      search: '',
      tableView: true,
      status: null,
      companies: [],
    },
    company: {
      search: '',
      tableView: true,
      status: null,
    },
    appusers: {
      search: '',
      tableView: true,
      status: null,
      companies: [],
    },
    companies: null,
    barcoding: {
      default: null,
      preference: null
    },
    timezone: {
      useBrowserTime: false
    },
    patient: {
      tableView: false,
      search: '',
      status:null,
      companies: [],
    },
    reporting: {
      tableView: false,
      search: '',
      status:null,
      companies: [],
    },
    absoluteScheduleTableView: false,
  }),
  actions: {
    toggleDrawer(status: boolean = false): void {
      this.isDrawerOpen = status;
    },
    toggleDarkMode(status: boolean = false): void {
      this.isDark = status;
    },
    //    updatePatientSearch(payload): void {
    //      this.patient.search = payload;
    //    },
    //    updatePatientFilters(payload): void {
    //      this.patient.search = payload;
    //    },
    //    updatePatientsTableView(payload): void {
    //      this.patientsTableView = payload;
    //    },
    //    updateAppUsersTableView(payload): void {
    //      this.appusers.tableView = payload;
    //    },
    //    updateMonitoringTableView(payload): void {
    //      this.monitoring.tableView = payload;
    //    },
    //    updateAbsoluteScheduleTableView(payload): void {
    //      this.absoluteScheduleTableView = payload;
    //    },
    //    updateMonitoringSearch(payload): void {
    //      this.monitoring.search = payload;
    //    },
    //    updateAppUsersSearch(payload): void {
    //      this.appuser.search = payload;
    //    },
    //    updateTagFilters(payload): void {
    //      this.tags.search = payload;
    //    },
    //    updateTagsTableView(payload): void {
    //      this.tags.tableView = payload;
    //    },
    //    updateCompanyFilters(payload): void {
    //      this.companies = payload;
    //    },
    //    updateCompanySearch(payload): void {
    //      this.company.search = payload;
    //    },
    //    updateCompaniesTableView(payload): void {
    //      this.company.tableView = payload;
    //    },
    updateDefaultCamera(payload: string): void {
      this.barcoding.default = payload;
    },
    updatePreference(payload: string): void {
      this.barcoding.preference = payload;
    },
    updateTimezone(payload: boolean): void {
      if (this.timezone == undefined) {
        this.timezone = {
          useBrowserTime: false
        };
      }
      this.timezone.useBrowserTime = payload;
      if (payload === false) {
        // @ts-expect-error: TODO
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        delete window.axios.defaults.headers.common['X-TIMEZONE-OFFSET-MINUTES'];
        return;
      }
      const date = new Date();
      // @ts-expect-error: TODO
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      window.axios.defaults.headers.common['X-TIMEZONE-OFFSET-MINUTES'] = date.getTimezoneOffset();
    },
    resetPreferences(): void {
      const endpoints = ['monitoring', 'patients', 'tags', 'companies', 'barcoding', 'timezone'];
      for (const endpoint of endpoints) {
        if (this.hasOwnProperty(endpoint)) {
          // @ts-expect-error: TODO
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          for (const [key, value] of Object.entries(this[endpoint])) {
            // @ts-expect-error: TODO
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (this[endpoint][key] != null) {
              // @ts-expect-error: TODO
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              this[endpoint][key] = null;
            }
          }
        }
      }
    },
  },
});

export type PreferencesStore = ReturnType<typeof usePreferencesStore>;
