import { useAuthStore } from "@/store/authentication.ts";
import { type RouteLocationNormalized, type NavigationGuardNext } from 'vue-router';

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authentication = useAuthStore();
  if (!authentication.access?.superAdminView) {
    next('/');
  } else {
    next();
  }
}
